/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import { checkError, getError } from '../../helpers/error';
import Alert from './Alert';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import MyUploadAdapter from '../CustomAdapterUpload';

import localForage from 'localforage';
import { CKEditor } from '@ckeditor/ckeditor5-react';

const TextEditor = (props) => {
  let { errors, ...input } = props;

  return (
    <div className={checkError(errors, input.name)}>
      {input.label && (
        <label className="input-label ">
          {input.label} {input.required && <span className="required">*</span>}
        </label>
      )}
      <CKEditor
        {...input}
        editor={ClassicEditor}
        config={{
          mediaEmbed: {
            previewsInData: true
          }
        }}
        onReady={(editor) => {
          editor.plugins.get('FileRepository').createUploadAdapter = (
            loader
          ) => {
            return new MyUploadAdapter(loader);
          };
        }}
      />
      <Alert messsage={getError(errors, input.name)} />
    </div>
  );
};

export default TextEditor;

TextEditor.propTypes = {
  errors: PropTypes.isRequired,
  input: PropTypes.isRequired
};
